@font-face {
    font-family: 'Greycliff CF Demi';
    src: url('../fonts/GreycliffCF-DemiBoldOblique.eot');
    src: url('../fonts/GreycliffCF-DemiBoldOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-DemiBoldOblique.woff2') format('woff2'),
        url('../fonts/GreycliffCF-DemiBoldOblique.woff') format('woff'),
        url('../fonts/GreycliffCF-DemiBoldOblique.ttf') format('truetype'),
        url('../fonts/GreycliffCF-DemiBoldOblique.svg#GreycliffCF-DemiBoldOblique') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('../fonts/GreycliffCF-Bold.eot');
    src: url('../fonts/GreycliffCF-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-Bold.woff2') format('woff2'),
        url('../fonts/GreycliffCF-Bold.woff') format('woff'),
        url('../fonts/GreycliffCF-Bold.ttf') format('truetype'),
        url('../fonts/GreycliffCF-Bold.svg#GreycliffCF-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('../fonts/GreycliffCF-Medium.eot');
    src: url('../fonts/GreycliffCF-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-Medium.woff2') format('woff2'),
        url('../fonts/GreycliffCF-Medium.woff') format('woff'),
        url('../fonts/GreycliffCF-Medium.ttf') format('truetype'),
        url('../fonts/GreycliffCF-Medium.svg#GreycliffCF-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('../fonts/GreycliffCF-Light.eot');
    src: url('../fonts/GreycliffCF-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-Light.woff2') format('woff2'),
        url('../fonts/GreycliffCF-Light.woff') format('woff'),
        url('../fonts/GreycliffCF-Light.ttf') format('truetype'),
        url('../fonts/GreycliffCF-Light.svg#GreycliffCF-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('../fonts/GreycliffCF-LightOblique.eot');
    src: url('../fonts/GreycliffCF-LightOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-LightOblique.woff2') format('woff2'),
        url('../fonts/GreycliffCF-LightOblique.woff') format('woff'),
        url('../fonts/GreycliffCF-LightOblique.ttf') format('truetype'),
        url('../fonts/GreycliffCF-LightOblique.svg#GreycliffCF-LightOblique') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('../fonts/GreycliffCF-BoldOblique.eot');
    src: url('../fonts/GreycliffCF-BoldOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-BoldOblique.woff2') format('woff2'),
        url('../fonts/GreycliffCF-BoldOblique.woff') format('woff'),
        url('../fonts/GreycliffCF-BoldOblique.ttf') format('truetype'),
        url('../fonts/GreycliffCF-BoldOblique.svg#GreycliffCF-BoldOblique') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF Extra';
    src: url('../fonts/GreycliffCF-ExtraBoldOblique.eot');
    src: url('../fonts/GreycliffCF-ExtraBoldOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-ExtraBoldOblique.woff2') format('woff2'),
        url('../fonts/GreycliffCF-ExtraBoldOblique.woff') format('woff'),
        url('../fonts/GreycliffCF-ExtraBoldOblique.ttf') format('truetype'),
        url('../fonts/GreycliffCF-ExtraBoldOblique.svg#GreycliffCF-ExtraBoldOblique') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('../fonts/GreycliffCF-Heavy.eot');
    src: url('../fonts/GreycliffCF-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-Heavy.woff2') format('woff2'),
        url('../fonts/GreycliffCF-Heavy.woff') format('woff'),
        url('../fonts/GreycliffCF-Heavy.ttf') format('truetype'),
        url('../fonts/GreycliffCF-Heavy.svg#GreycliffCF-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF Extra';
    src: url('../fonts/GreycliffCF-ExtraBold.eot');
    src: url('../fonts/GreycliffCF-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-ExtraBold.woff2') format('woff2'),
        url('../fonts/GreycliffCF-ExtraBold.woff') format('woff'),
        url('../fonts/GreycliffCF-ExtraBold.ttf') format('truetype'),
        url('../fonts/GreycliffCF-ExtraBold.svg#GreycliffCF-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF Demi';
    src: url('../fonts/GreycliffCF-DemiBold.eot');
    src: url('../fonts/GreycliffCF-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-DemiBold.woff2') format('woff2'),
        url('../fonts/GreycliffCF-DemiBold.woff') format('woff'),
        url('../fonts/GreycliffCF-DemiBold.ttf') format('truetype'),
        url('../fonts/GreycliffCF-DemiBold.svg#GreycliffCF-DemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('../fonts/GreycliffCF-HeavyOblique.eot');
    src: url('../fonts/GreycliffCF-HeavyOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-HeavyOblique.woff2') format('woff2'),
        url('../fonts/GreycliffCF-HeavyOblique.woff') format('woff'),
        url('../fonts/GreycliffCF-HeavyOblique.ttf') format('truetype'),
        url('../fonts/GreycliffCF-HeavyOblique.svg#GreycliffCF-HeavyOblique') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('../fonts/GreycliffCF-RegularOblique.eot');
    src: url('../fonts/GreycliffCF-RegularOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-RegularOblique.woff2') format('woff2'),
        url('../fonts/GreycliffCF-RegularOblique.woff') format('woff'),
        url('../fonts/GreycliffCF-RegularOblique.ttf') format('truetype'),
        url('../fonts/GreycliffCF-RegularOblique.svg#GreycliffCF-RegularOblique') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('../fonts/GreycliffCF-MediumOblique.eot');
    src: url('../fonts/GreycliffCF-MediumOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-MediumOblique.woff2') format('woff2'),
        url('../fonts/GreycliffCF-MediumOblique.woff') format('woff'),
        url('../fonts/GreycliffCF-MediumOblique.ttf') format('truetype'),
        url('../fonts/GreycliffCF-MediumOblique.svg#GreycliffCF-MediumOblique') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('../fonts/GreycliffCF-Regular.eot');
    src: url('../fonts/GreycliffCF-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GreycliffCF-Regular.woff2') format('woff2'),
        url('../fonts/GreycliffCF-Regular.woff') format('woff'),
        url('../fonts/GreycliffCF-Regular.ttf') format('truetype'),
        url('../fonts/GreycliffCF-Regular.svg#GreycliffCF-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

