@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(../src/fonts/fonts.css);

@layer components {
    html {
        scroll-behavior: smooth;
    }
    .btn {
        @apply px-3 py-2 text-xs text-white rounded;
    }

    .content-container {
        height: calc(100vh - theme("spacing.24"));
    }
    @media only screen and (max-width: 640px) {
        .content-container {
            height: calc(100vh - theme("spacing.32"));
        }
    }
    .content {
        width: calc(100% - theme("spacing.56"));
    }
    .content-extra {
        width: calc(100% - theme("spacing.12"));
    } 

    .frontSpaContainer {
        height: calc(100vh - 7.5rem);
    }
    .z-index-minus {
        z-index: -1;
    }
    .tab-content {
        @apply max-h-0;
    }
    .tab input:checked ~ .tab-content {
        max-height: 100%;
    }
    .tab input[type="checkbox"] + label::after {
        @apply flex items-center justify-center float-right w-6 h-6 text-white bg-gray-700 border rounded-full;
        content: "+";
    }
    .tab input[type="checkbox"]:checked + label::after {
        content: "-";
    }
    .imgHover:hover .imgHoverDisplay {
        display: flex;
    }
    .displayBox {
        dispaly: flex;
    }

    .active {
        @apply text-white bg-gray-500 border-l-4 border-red-500;
    }
    /* .active:first-child {
        @apply text-black bg-transparent border-none dark:bg-gray-800 dark:hover:bg-gray-500;
    } */
    .active2 {
        @apply text-white bg-gray-400 border-l-4 border-red-500;
    }
    .border-left-green-8 {
        border-left-color: #10b981;
        border-left-width: 8px;
    }
    .border-left-red-8 {
        border-left-color: #ef4444;
        border-left-width: 8px;
    }

    /* slider css */
    .slider .slick-prev {
        left: 5%;
    }
    .slider .slick-next {
        right: 5%;
    }
    .slider .slick-next::before {
        color: #f59e0b !important;
        opacity: 1 !important;
    }
    .slider .slick-prev::before {
        color: #f59e0b !important;
        opacity: 1 !important;
    }
    .slides .slick-next {
        position: absolute;
        top: 50%;
        z-index: 1;
    }
    .slides .slick-prev {
        position: absolute;
        top: 50%;
        z-index: 1;
    }
    .slides .slick-slide > div {
        margin: 0 0px;
    }
    /* @media screen and (max-width: 640px) {
        .slides .slick-track {
            height: 400px !important;
        } */

    /* carousel slider style start */

    .multiSlides .slick-prev,
    .multiSlides .slick-next {
        position: absolute;
        top: 50%;
        z-index: 1;
    }

    .multiSlides .slick-prev {
        left: 5%;
    }

    .multiSlides .slick-next {
        right: 5%;
    }
    .multiSlides .slick-slide > div {
        margin: 0 10px;
    }

    .slick-list {
        margin: 0 -10px;
    }

    .slick-slide *:focus {
        outline: none;
    }
    .dropzone {
        text-align: center;
        padding: 20px;
        border: 3px dashed #eeeeee;
        background-color: #fafafa;
        color: #bdbdbd;
      }
    .main-content{ max-width: 1140px;
        @apply pl-1.5 pr-1.5 m-auto; 
    }
    .section-pedding{
        @apply pt-12 pb-12;
    }
    .main-title{
        @apply  mb-8 text-xl uppercase md:text-2xl lg:text-3xl dark:text-yellow-400;
    }
    .slick-track{width:100% !important;}
    .team-box-members{ position: relative; }
    .team-box-members .team-box-img img{
        @apply w-full rounded-t-sm h-1/4 ;
    }
    .team-box-members .team-box-detail{  top:100%;   background:rgba(0, 0, 0, 0.5); transition: 0.5s;
        @apply absolute left-0 w-full px-2 py-4 transition-all;
    }
    .team-box-members:hover .team-box-detail{
            @apply bottom-0 top-auto;
    }
    .react-datepicker-popper{z-index:91 !important;}
    .slide-navigation{transition: 0.5s ease-in-out !important;
    }
    .c-point-auto {cursor: auto !important; text-decoration: none !important; color:#6c7280 !important;
        @apply text-gray-500;
    }
}
:root {
    /* --primary: rebeccapurple; */
    --secondary: #EDEEF3;
    --secondblue: #3276B4;
}

/* Scrollbar styles */

/* Firefox */
* {
    scrollbar-width: thin;
    /* scrollbar-color: var(--primary) var(--secondary); */
    scrollbar-color: var(--secondary);    
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    /* background: var(--primary); */
}

*::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    /*   background: repeating-linear-gradient(
        45deg,
        var(--secondary),
        var(--secondary) 5px,
        var(--primary) 5px,
        var(--primary) 10px
    ); */
    border-radius: 14px;
    /* border: 3px solid var(--primary); */
}
.secondary-blue-scroll {
    scrollbar-color: var(--secondblue);
}
.secondary-blue-scroll::-webkit-scrollbar-thumb {
    background-color: var(--secondblue);
}
.group .bg-blue-theme {
    --tw-bg-opacity: .8;
}
.group .bg-blue-theme::before {
    border-top-color: #000352;
}
.group2:hover .group2-hover\:inline-block {
    display: inline-block;
}
.group2:hover .group2-hover\:opacity-100 {
    opacity: 1;
}
:disabled {
    opacity: .5;
}
#drop_down_with_links {
    position: relative;
}
#drop_down_with_links #trigger {
    border: none;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 8px;
    border: 2px solid #e9e9e9;
}
#drop_down_with_links #menu {
    position: absolute;
    top: 46px;
    left: 0px;
    width: 180px;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0,0,0,.15);
    padding: 8px;
    transition: 500ms ease-in;
}
#drop_down_with_links #menu.closed {
    display: none;
}
#drop_down_with_links li > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    text-decoration: none;
    cursor: pointer;
    padding: 10px;
}
.accordion-item:first-of-type .accordion-button,
.accordion-item:not(:first-of-type) .accordion-button,
.accordion-item:first-of-type {
    border-radius: .5rem;
}
.accordion-button {
    border: 1px solid #919195;
}
.accordion-button:not(.collapsed) {
    background-color: #EDEEF3;
}
.accordion-button:not(.collapsed) {
    color: #252B42;    
}
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%232563ex%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
